import styled from 'styled-components'

const TableRow = styled.div`
  height: 56px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px solid #C9D0E4;
  &:last-of-type {
    border-bottom: none;
  }
`

export default TableRow
