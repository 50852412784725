import React from 'react'
import { arrayOf, string, node, number } from 'prop-types'
import styled from 'styled-components'
import { semiBoldAlias } from '../../libs/theme'

const TableLayout = styled.div`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`

const TableHeader = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  text-align: ${props => props.textAlign};
  line-height: 18px;
  padding-bottom: 8px;
  width: 100%;
  ${semiBoldAlias};
`

const TableRowHeader = styled.div`
  border-bottom: 1px solid #c9d0e4;
  display: flex;
  justify-content: stretch;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
`

const getClass = value => value.replace(' ', '-').toLowerCase()

const Table = ({
  headers,
  headerColor,
  children,
  fontSize,
  textAlign,
  className,
}) => (
  <TableLayout
    className={`table-layout${className ? ` ${className}` : ''}`}
  >
    <TableRowHeader className="table-row-header">
      {headers.map(header => (
        <TableHeader
          className={`cell ${getClass(header)}`}
          key={header}
          color={headerColor}
          textAlign={textAlign}
          fontSize={fontSize}
        >
          {header}
        </TableHeader>
      ))}
    </TableRowHeader>
    {children}
  </TableLayout>
)

Table.propTypes = {
  children: node.isRequired,
  className: string,
  fontSize: number,
  headerColor: string,
  headers: arrayOf(string).isRequired,
  textAlign: string,
}

Table.defaultProps = {
  className: '',
  fontSize: 13,
  headerColor: '#5F5E70',
  textAlign: 'left',
}

export default Table
