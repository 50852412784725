import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import styled from 'styled-components'

import { zendeskShape } from '../helpers/zendesk'
import formatDate from '../libs/formatDate'
import Table from './Table/Table'
import TableRow from './Table/TableRow'
import TableCell from './Table/TableCell'
import LinkIcon from '../icons/LinkIcon'

const TableWrapper = styled.div`
  padding: 0 32px;
  border-bottom: 1px solid #C9D0E4;
`

const TicketTable = ({ tickets, highlight, highlit }) => (
  <TableWrapper>
    <h6>{`Zendesk (${tickets.length})`}</h6>
    <Table headers={['Ticket', 'Title', 'Cloud', 'Region', 'Support plan', 'Created', 'Linked']}>
      {tickets.map(({ cloud, created, href, id, region, summary, supportPlan, jira }, i) => {
        const isHighlit = jira.find(j => highlit.includes(j))
        return (
          <TableRow
              // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={`table-row${isHighlit ? ' highlit' : ''}`}
            onMouseEnter={() => highlight(jira)}
            onMouseLeave={() => highlight([])}
          >
            <TableCell className="id">
              <a
                href={href}
                className="zendesk-title"
                target="_blank"
                rel="noopener noreferrer"
              >
                {id}
              </a>
            </TableCell>
            <TableCell className="summary">{summary}</TableCell>
            <TableCell className="cloud">{cloud}</TableCell>
            <TableCell className="region">{region}</TableCell>
            <TableCell className="support-plan">{supportPlan}</TableCell>
            <TableCell className="created">{formatDate(created)}</TableCell>
            <TableCell className="linked">{jira.length > 0 && <LinkIcon />}</TableCell>
          </TableRow>
        )
      })}
    </Table>
  </TableWrapper>
)

TicketTable.propTypes = {
  tickets: arrayOf(shape(zendeskShape)).isRequired,
  highlight: func.isRequired,
  highlit: arrayOf(string).isRequired,
}

export default TicketTable
