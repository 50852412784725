import React from 'react'
import styled from 'styled-components'

export default styled(({ className }) => (
  <div className={className}>
    <span className="meter" />
  </div>
))`
  margin: 30px;
  height: 20px;
  position: relative;
  background: #555;
  border-radius: 25px;
  overflow: hidden;
  > span {
    display: block;
    height: 100%;
    background-color: rgb(43, 194, 83);
    background-image: linear-gradient( center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: absolute;
    overflow: hidden;
    width: ${props => props.progress}%;
    transition: width 0.5s linear;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient( -45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      overflow: hidden;
    }
  }
`
