import React, { Component } from 'react'
import styled from 'styled-components'

import PlatformIcon from './icons/PlatformIcon'
import LoginForm from './components/LoginForm'

import { getAccessToken } from './helpers/auth'
import Lighthouse from './Lighthouse'
import { semiBoldAlias } from './libs/theme'
import LoadingBar from './components/LoadingBar'

if (process.env.PLATFORM_TREE_ID) {
  console.log(`Build ID: %c${process.env.PLATFORM_TREE_ID}`, 'color: green') // eslint-disable-line no-console
}

const AppLayout = styled.div`
  background: #F0F2F5;
  height: 100%;
  width: 100%;
`

const HeaderLayout = styled.header`
  height: 64px;
  padding: 16px 40px;
  background: #1A192B;
  color: #fff;
  display: flex;
  box-sizing: border-box;
  svg {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
  h1 {
    font-size: 12px;
    margin-left: 24px;
    ${semiBoldAlias()};
    flex: 1 1 auto;
  }
  button {
    float: right;
    background: none;
    border: none;
    color: #f7f7f9;
    font-size: 1em;
    font-weight: bold;
    margin: 20px;
  }
`

const BodyLayout = styled.div`
  padding: 40px;
  font-family: 'Open Sans', sans-serif;
  h1 {
    font-size: 20px;
    line-height: 33px;
    ${semiBoldAlias('bold')};
    margin: 0 0 32px;
  }
  a {
    color: #4786FF;
    text-decoration: none;
  }
`

export default class App extends Component {
  state = {
    email: '',
    token: '',
    auth: false,
    error: false,
  }

  async componentDidMount() {
    if (process.env.REACT_APP_QUALITY === 'garbage') {
      const email = sessionStorage.getItem('atlassianEmail')
      const token = sessionStorage.getItem('atlassianToken')
      if (!email || !token) return
      this.setState({
        auth: true,
        error: false,
      })
    } else {
      const accessToken = await getAccessToken()
      if (!accessToken) return
      this.setState({
        auth: true,
        error: false,
      })
    }
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSubmit = event => {
    const { email, token } = this.state
    sessionStorage.setItem('atlassianEmail', email)
    sessionStorage.setItem('atlassianToken', token)
    this.setState({
      email: '',
      token: '',
      auth: true,
      error: false,
    })
    event.preventDefault()
  }

  handleLogoutWithError = () => {
    this.handleLogout(true)
    this.setState({ error: true })
  }

  handleLogout = (error = false) => {
    sessionStorage.removeItem('atlassianEmail')
    sessionStorage.removeItem('atlassianToken')
    if (!error) localStorage.removeItem('atlassianAccessToken')
    this.setState({
      email: '',
      token: '',
      auth: false,
    })
  }

  getBody = () => {
    const { auth, email, token, error } = this.state
    if (auth) {
      return (
        <Lighthouse
          handleLogoutWithError={this.handleLogoutWithError}
        />
      )
    }
    if (process.env.REACT_APP_QUALITY === 'garbage') {
      return (
        <LoginForm
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          email={email}
          token={token}
          error={error}
        />
      )
    }
    return <LoadingBar progress={1} />
  }

  render() {
    const { auth } = this.state
    return (
      <AppLayout>
        <HeaderLayout>
          <PlatformIcon />
          <h1>Lighthouse </h1>
          {process.env.REACT_APP_QUALITY === 'garbage' && auth && (
            <button onClick={this.handleLogout} type="button">
              Log out
            </button>
          )}
        </HeaderLayout>
        <BodyLayout>{this.getBody()}</BodyLayout>
      </AppLayout>
    )
  }
}
