import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import styled from 'styled-components'

import formatDate from '../libs/formatDate'
import Table from './Table/Table'
import TableRow from './Table/TableRow'
import TableCell from './Table/TableCell'
import LinkIcon from '../icons/LinkIcon'

const TableWrapper = styled.div`
  padding: 0 32px;
`

const IssueTable = ({ issues, highlight, highlit }) => (
  <TableWrapper>
    <h6>{`OPs Escalation (${issues.length})`}</h6>
    <Table headers={['Ticket', 'Title', 'Category', 'Region', 'Foundation', 'Created', 'Linked']} className="issue-table">
      {issues.map(({ category, created, href, key, region, summary, foundation, zendesk }, i) => {
        const isHighlit = highlit.includes(key)
        return (
          <TableRow
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={`table-row${isHighlit ? ' highlit' : ''}`}
            onMouseEnter={() => highlight([key])}
            onMouseLeave={() => highlight([])}
          >
            <TableCell className="key">
              <a
                href={href}
                className="jira-title"
                target="_blank"
                rel="noopener noreferrer"
              >
                {key}
              </a>
            </TableCell>
            <TableCell className="summary">{summary}</TableCell>
            <TableCell className="category">{category}</TableCell>
            <TableCell className="region">{region}</TableCell>
            <TableCell className="foundation">{foundation}</TableCell>
            <TableCell className="created">{formatDate(created)}</TableCell>
            <TableCell className="linked">{zendesk.length > 0 && <LinkIcon />}</TableCell>
          </TableRow>
        )
      })}
    </Table>
  </TableWrapper>
)

IssueTable.propTypes = {
  issues: arrayOf(
    shape({
      created: string.isRequired,
      foundation: string,
      href: string.isRequired,
      key: string.isRequired,
      summary: string.isRequired,
    }),
  ).isRequired,
  highlight: func.isRequired,
  highlit: arrayOf(string).isRequired,
}

export default IssueTable
