import React from 'react'
import styled from 'styled-components'
import { bool, string, func } from 'prop-types'

const LoginWrapper = styled.div`
  margin: 0 auto;
  width: 520px;
  box-sizing: border-box;
  .error {
    background: #ce0000;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 10px;
  }
  form {
    width: 400px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 10px 20px;
    label {
      display: block;
      margin: 0 0 30px;
      box-sizing: border-box;
    }
    input {
      display: block;
      width: 100%;
      padding: 10px 15px;
      margin: 5px 0 10px;
      box-sizing: border-box;
      &[type='submit'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1em;
        border-radius: 5px;
      }
    }
  }
`

const LoginForm = ({ error, handleSubmit, email, token, handleChange }) => (
  <LoginWrapper>
    <h1>Please enter your JIRA email address and API token</h1>
    <p>
      {'To generate a token, visit '}
      <a
        href="https://id.atlassian.com/manage/api-tokens"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://id.atlassian.com/manage/api-tokens
      </a>
      .
    </p>
    {error && (
      <p className="error">
        Error: could not authenticate with JIRA.
        <br />
        Please check your credentials and try again.
      </p>
    )}
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">
        Email
        <input type="text" value={email} id="email" onChange={handleChange} />
      </label>
      <label htmlFor="token">
        API token
        <input type="password" value={token} id="token" onChange={handleChange} />
      </label>
      <input type="submit" value="Submit" />
    </form>
  </LoginWrapper>
)

LoginForm.propTypes = {
  error: bool.isRequired,
  handleSubmit: func.isRequired,
  email: string.isRequired,
  token: string.isRequired,
  handleChange: func.isRequired,
}

export default LoginForm
