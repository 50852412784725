import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChevronIcon from '../icons/ChevronIcon'

const SettingsLineWrapper = styled.div`
  margin-bottom: 8px;
  border-radius: 4px;
  background: #fff;
  &.released {
    background: rgba(255, 255, 255, 0.6);
  }
  &.open {
    margin: 16px 0;
    box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
    background: #fff;
    .setting-line {
      background: #f5f7fa;
      border-radius: 4px 4px 0 0;
      &:hover {
        background: #f5f7fa;
        box-shadow: none;
        .open-text {
          display: none;
        }
      }
    }
    .action .chevron {
      transform: rotate(180deg);
    }
  }
  .action {
    flex: 0 0 64px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
  }
  button {
    height: 32px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background: transparent;
    padding: 0;
    &:hover, &:focus {
      box-shadow: none;
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
    }
  }
`
const SettingLineLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  box-sizing: border-box;
  font-size: 16px;
  color: #4a495e;
  border-radius: 4px;
  text-decoration: none;
  hr {
    background: #d8e2f0;
  }
  &:hover {
    background: rgba(71, 134, 255, 0.13);
    box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
    .chevron svg path {
      fill: #4786ff;
    }
  }
`

const SettingLineInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  color: #4b6180;
  strong {
    font-family: "Open sans", sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const SettingLine = ({ className, children, id, info, isOpen, onClick, openText }) => (
  <SettingsLineWrapper
    className={`settings-item${
      isOpen ? ' open' : ''
    }${className ? ` ${className}` : ''}`}
  >
    <SettingLineLayout className="setting-line">
      <SettingLineInfo id={id}>
        {info}
      </SettingLineInfo>
      <div className="action">
        <button
          id={`${id}-edit`}
          onClick={e => {
            e.preventDefault()
            onClick()
            document.activeElement.blur()
          }}
          aria-label={openText}
          type="button"
        >
          <ChevronIcon isOpen={isOpen} />
        </button>
      </div>
    </SettingLineLayout>
    {children}
  </SettingsLineWrapper>
)

SettingLine.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  openText: PropTypes.string.isRequired,
  className: PropTypes.string,
}

SettingLine.defaultProps = { isOpen: false, className: '' }

export default SettingLine
