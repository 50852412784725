import { transformIssue } from './jira'
import { zendeskTransform } from './zendesk'

const combineIssues = (problemIssues, relatedIssues, zendeskTickets) => {
  const data = []
  const zdTickets = zendeskTickets.map(t => zendeskTransform(t))
  problemIssues.forEach(i => {
    const issueData = transformIssue(i)
    const deployIssues = i.fields.issuelinks ? i.fields.issuelinks
      .filter(l => l.inwardIssue && l.type.inward === 'is blocked by')
      .map(l => l.inwardIssue.key) : []
    const escalationIssues = i.fields.issuelinks ? i.fields.issuelinks
      .filter(l => l.outwardIssue && l.type.outward === 'causes')
      .map(l => l.outwardIssue.key) : []
    issueData.deployIssues = deployIssues.reduce(
      (acc, key) => {
        const rIssue = relatedIssues.find(r => r.key === key)
        if (rIssue) {
          acc.push(transformIssue(rIssue))
        }
        return acc
      },
      [],
    )
    issueData.escalationIssues = escalationIssues.reduce(
      (acc, key) => {
        const rIssue = relatedIssues.find(r => r.key === key)
        const zendesk = zdTickets
          .reduce((tAcc, t) => {
            const linked = t.jira.filter(j => j === key && !j.startsWith('PF-'))
            return [...tAcc, ...linked]
          }, [])
        if (rIssue) {
          acc.push({ ...transformIssue(rIssue), zendesk })
        }
        return acc
      },
      [],
    )
    issueData.zendeskTickets = zdTickets.filter(t => {
      let match = false
      t.jira.forEach(j => {
        if (j === i.key || deployIssues.includes(j) || escalationIssues.includes(j)) {
          match = true
        }
      })
      return match
    })
    data.push(issueData)
  })
  return data.sort((a, b) => {
    const aSum = a.zendeskTickets.length + a.escalationIssues.length
    const bSum = b.zendeskTickets.length + b.escalationIssues.length
    if (aSum === bSum) return 0
    return bSum - aSum
  })
}

export default combineIssues
