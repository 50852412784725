import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'

import toQuerystring from 'to-querystring'
import Client from 'platformsh-client'

import { getAccessToken, getApiConfig } from './helpers/auth'
import { fetchProblemIssues, fetchRelatedIssues } from './helpers/jira'
import { fetchTickets } from './helpers/zendesk'
import combineIssues from './helpers/combineIssues'
import ProblemItem from './components/ProblemItem'
import LoadingBar from './components/LoadingBar'

const client = new Client(getApiConfig())

const Lighthouse = ({ handleLogoutWithError }) => {
  const [authError, setAuthError] = useState(false)
  const [problemIssues, setProblemIssues] = useState([])
  const [relatedIssues, setRelatedIssues] = useState([])
  const [zendeskTickets, setZendeskTickets] = useState([])
  const [expandedItem, setExpandedItem] = useState('')
  const [fetching, setFetching] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [progress, setProgress] = useState(0)
  const [totalTickets, setTotalTickets] = useState(0)

  const doAuth = () => {
    if (process.env.REACT_APP_QUALITY === 'garbage') {
      const email = sessionStorage.getItem('atlassianEmail')
      const token = sessionStorage.getItem('atlassianToken')
      if (!email || !token) {
        setAuthError(true)
      }
    } else if (!authError) {
      const accessToken = localStorage.getItem('atlassianAccessToken') || getAccessToken()
      if (!accessToken) return
      if (accessToken === 'error') {
        setAuthError(true)
      }
    }
  }

  const handleFetch = async uri => {
    const { next, tickets, count } = await fetchTickets(uri)
    setZendeskTickets(zdt => ([...zdt, ...tickets]))
    setTotalTickets(count)
    setProgress(prog => (prog + tickets.length))
    return next
  }

  const fetchAll = async () => {
    setFetching(true)
    const pIssues = await fetchProblemIssues()
    setProblemIssues(pIssues)
    const rIssues = await fetchRelatedIssues(pIssues)
    setRelatedIssues(rIssues)
    const jiraKeys = [...pIssues.map(i => i.key), ...rIssues.map(i => i.key)]
    const queryString = toQuerystring({
      all: 1,
      filter: {
        jira: {
          value: jiraKeys,
          operator: 'IN',
        },
      },
    }, '', { arrayPrefix: '[]' })
    let uri = `${client.getConfig().account_url}/platform/tickets?${queryString}`
    while (uri) {
      try {
        // eslint-disable-next-line no-await-in-loop
        uri = await handleFetch(uri)
      } catch (e) {
        console.log('Error, gonna log out...') // eslint-disable-line no-console
        let error = e
        // Error
        if (e) {
          if (e.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            error = {
              data: e.response.data,
              status: e.response.status,
              headers: e.response.headers,
            }
          } else if (e.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            error = e.request
          } else {
            // Something happened in setting up the request that triggered an Error
            error = e.message
          }
        }
        console.log({ status: 'failed', error }) // eslint-disable-line no-console
        handleLogoutWithError(true)
        break
      }
    }
    setFetching(false)
    setFetched(true)
  }

  useEffect(() => {
    doAuth()
    if (!fetching && !fetched) {
      fetchAll()
    }
  }, [])

  if (authError) {
    return (
      <div className="error">
        Error authenticating with JIRA. Try again, or contact a developer.
      </div>
    )
  }
  if (totalTickets === 0) {
    return <LoadingBar progress={1} />
  }
  if (progress / totalTickets < 1) {
    return <LoadingBar progress={(progress / totalTickets) * 100} />
  }
  const combinedIssueData = combineIssues(problemIssues, relatedIssues, zendeskTickets)
  return (
    <div>
      {combinedIssueData.length > 0 ? (
        <div>
          <h1>{`Known Issues (${combinedIssueData.length})`}</h1>
          {combinedIssueData
            .map(
              i => (
                <ProblemItem
                  problemIssue={i}
                  expand={key => (expandedItem === key ? setExpandedItem('') : setExpandedItem(key))}
                  expanded={i.key === expandedItem}
                />
              ),
            )
          }
        </div>
      )
        : (
          <div className="no-issues">
            <span>No issues found.</span>
            <button onClick={() => window.location.reload(true)} type="button">
              Try again
            </button>
          </div>
        )}
    </div>
  )
}

Lighthouse.propTypes = { handleLogoutWithError: func.isRequired }

export default Lighthouse
