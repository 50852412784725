import axios from 'axios'
import { string } from 'prop-types'
import { getJiraUrl } from './config'

export const transformIssue = i => ({
  key: i.key,
  href: `https://platformsh.atlassian.net/browse/${i.key}`,
  summary: i.fields.summary,
  foundation: i.fields.customfield_13179 || '',
  created: i.fields.created,
  category: i.fields.customfield_13279 ? i.fields.customfield_13279.value : '',
  region: i.fields.customfield_13171 ? i.fields.customfield_13171.value : '',
  status: i.fields.status.name,
  workaround: i.fields.customfield_13285 && i.fields.customfield_13285.value === 'Yes',
})

export const issueShape = {
  category: string.isRequired,
  created: string.isRequired,
  foundation: string,
  href: string.isRequired,
  key: string.isRequired,
  region: string.isRequired,
  summary: string.isRequired,
}

export const getProblemStatus = issue => {
  const pfMap = [
    { jira: 'New Issue', lighthouse: 'New' },
    { jira: 'In Progress', lighthouse: 'In progress' },
    { jira: 'Done', lighthouse: 'Pending release' },
    { jira: 'Duplicate', lighthouse: 'Won\'t fix' },
    { jira: 'Won\'nt Fix', lighthouse: 'Won\'t fix' },
  ]
  if (issue.deployIssues.length === 0) {
    const match = pfMap.find(m => m.jira === issue.status)
    return match ? match.lighthouse : issue.status
  }
  const deployMap = [
    { jira: 'To Do', lighthouse: 'Release scheduled' },
    { jira: 'In Progress', lighthouse: 'Release scheduled' },
    { jira: 'In Review/Blocked', lighthouse: 'Release scheduled' },
    { jira: 'Done', lighthouse: 'Released' },
  ]
  return issue.deployIssues.reduce((acc, i) => {
    if (acc !== 'Release scheduled') {
      const match = deployMap.find(m => m.jira === i.status)
      return match ? match.lighthouse : i.status
    }
    return acc
  }, '')
}

const jiraFetch = async (jql, startAt = 0) => {
  const url = getJiraUrl()
  const config = { params: { jql, startAt } }
  if (process.env.REACT_APP_QUALITY === 'garbage') {
    config.auth = {
      username: sessionStorage.getItem('atlassianEmail'),
      password: sessionStorage.getItem('atlassianToken'),
    }
  } else {
    const token = JSON.parse(localStorage.getItem('atlassianAccessToken'))
    config.headers = {
      Authorization: `Bearer ${token.access_token}`,
      Accept: 'application/json',
    }
  }
  try {
    const { data } = await axios.get(url, config)
    let results = data.issues
    if (data.startAt + data.maxResults < data.total) {
      results = [
        ...results,
        ...await jiraFetch(jql, data.startAt + data.maxResults),
      ]
    }
    return results
  } catch (e) {
    if (e.response && e.response.status === 401) {
      // eslint-disable-next-line no-console
      console.log('unauthorized, logging out ...')
      sessionStorage.removeItem('atlassianEmail')
      sessionStorage.removeItem('atlassianToken')
    }
    return Promise.reject(e.response)
  }
}

export const fetchProblemIssues = () => jiraFetch('type = "Problem"')

export const fetchRelatedIssues = problemIssues => {
  const keys = problemIssues.reduce((acc, current) => {
    current.fields.issuelinks.forEach(l => {
      if (l.inwardIssue) {
        acc.push(l.inwardIssue.key)
      }
      if (l.outwardIssue) {
        acc.push(l.outwardIssue.key)
      }
    })
    return acc
  }, [])

  return jiraFetch(`key in ("${keys.join('", "')}")`)
}
