import React, { useState } from 'react'
import styled from 'styled-components'
import { arrayOf, bool, func, shape } from 'prop-types'

import ReactTooltip from 'react-tooltip'
import { getProblemStatus, issueShape } from '../helpers/jira'
import IssueTable from './IssueTable'
import SettingLine from './SettingLine'
import formatDate from '../libs/formatDate'
import TicketTable from './TicketTable'
import { zendeskShape } from '../helpers/zendesk'
import { semiBoldAlias } from '../libs/theme'
import StatusLozenge from './StatusLozenge'

const InfoLayout = styled.div`
  width: 100%;
  display: flex;
  font-size: 13px;
  color: #5F5E70;
  align-items: center;
  ${semiBoldAlias()};
  &.released {
    > div {
      opacity: 0.8;
    }
  }
  .key {
    flex: 0 0 200px;
    padding-left: 32px;
    font-size: 15px;
    font-weight: bold;
  }
  .summary {
    color: #302F45;
    flex: 1 1 auto;
    font-size: 15px;
    display: flex;
    .text {
      flex: 0 1 auto;
    }
    .workaround {
      flex: 0 0 45px;
      padding-left: 16px;
      .workaround-lozenge {
        cursor: pointer;
        background: #F0F2F5;
        color: #98A0AB;
        border-radius: 2px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
  .tickets {
    flex: 0 0 120px;
  }
  .escalations {
    flex: 0 0 160px;
  }
  .created {
    flex: 0 0 145px;
  }
  .status {
    flex: 0 0 151px;
  }
`

const BodyLayout = styled.div`
  padding: 0 0 8px;
  h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    margin-top: 24px;
  }
  .table-row-header .cell {
    color: #5F5e70;
  }
  .key, .id, .ticket {
    flex: 0 0 168px;
    padding-left: 16px;
  }
  .table-row {
    color: #98A0AB;
    .title, .summary {
      font-size: 15px;
      color: #38485E;
    }
    &.highlit, &:hover {
      color: #5F5E70;
      background: #E7F0FF;
      svg path {
        fill: #4A495E;
      }
    }
  }
  .cloud, .category {
    flex: 0 0 120px;
  }
  .region {
    flex: 0 0 160px;
  }
  .support-plan, .foundation {
    flex: 0 0 145px;
  }
  .created {
    flex: 0 0 125px;
  }
  .linked {
    flex: 0 0 58px;
    padding-right: 16px;
  }
`

const DeployLayout = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 24px;
  .heading {
    flex: 0 0 200px;
    padding-left: 32px;
    font-size: 13px;
    color: #5F5E70;
    ${semiBoldAlias()};
  }
  .summary {
    display: flex;
    flex-direction: column;
  }
`

const ProblemItem = ({ problemIssue, expanded, expand }) => {
  const [highlitEscalation, highlightEscalation] = useState([])
  const [highlitZendesk, highlightZendesk] = useState([])
  const problemStatus = getProblemStatus(problemIssue)
  return (
    <SettingLine
      key={problemIssue.key}
      id={`issue-${problemIssue.key}`}
      className={problemStatus === 'Released' ? 'released' : ''}
      info={(
        <InfoLayout className={problemStatus === 'Released' ? 'released' : ''}>
          <div className="key">
            <a
              href={problemIssue.href}
              className="jira-title"
              target="_blank"
              rel="noopener noreferrer"
            >
              {problemIssue.key}
            </a>
          </div>
          <div className="summary">
            <span className="text">
              {problemIssue.summary}
            </span>
            {problemIssue.workaround && (
              <span className="workaround">
                <span
                  className="workaround-lozenge"
                  data-tip="Workaround available"
                  data-for={`workaround-${problemIssue.key}`}
                >
                  W
                </span>
                <ReactTooltip id={`workaround-${problemIssue.key}`} place="bottom" type="dark" effect="solid" />
              </span>
            )}
          </div>
          <div className="tickets">
            {`${problemIssue.zendeskTickets.length} ${problemIssue.zendeskTickets.length === 1 ? 'ticket' : 'tickets'}`}
          </div>
          <div className="escalations">
            {`${problemIssue.escalationIssues.length} ${problemIssue.escalationIssues.length === 1 ? 'escalation' : 'escalations'}`}
          </div>
          <div className="created">
            {formatDate(problemIssue.created)}
          </div>
          <div className="status">
            <StatusLozenge status={problemStatus} />
          </div>
        </InfoLayout>
      )}
      isOpen={expanded}
      openText="View"
      onClick={() => {
        expand(problemIssue.key)
      }}
    >
      {expanded && (
        <BodyLayout>
          {problemIssue.deployIssues.length > 0 && (
            <DeployLayout>
              <div className="heading">Deploy Ticket:</div>
              <div className="summary">
                {problemIssue.deployIssues.map(i => (
                  <a
                    href={i.href}
                    className="jira-title"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${i.key}: ${i.summary}`}
                  </a>
                ))}
              </div>
            </DeployLayout>
          )}
          <TicketTable
            tickets={problemIssue.zendeskTickets}
            highlit={highlitZendesk}
            highlight={highlightEscalation}
          />
          <IssueTable
            issues={problemIssue.escalationIssues}
            highlit={highlitEscalation}
            highlight={highlightZendesk}
          />
        </BodyLayout>
      )}
    </SettingLine>
  )
}


ProblemItem.propTypes = {
  problemIssue: shape({
    ...issueShape,
    deployIssues: arrayOf(shape(issueShape)),
    escalationIssues: arrayOf(shape(issueShape)),
    zendeskTickets: arrayOf(shape(zendeskShape)),
  }).isRequired,
  expanded: bool.isRequired,
  expand: func.isRequired,
}

export default ProblemItem
