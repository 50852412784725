import { number, string } from 'prop-types'
import { request } from '../libs/platform'

const clouds = [
  { key: 'cloud_platform', value: 'Platform.sh' },
  { key: 'cloud_sensio', value: 'Symfony' },
  { key: 'cloud_magento', value: 'Magento' },
  { key: 'cloud_ez', value: 'eZ' },
]

const regions = [
  { key: 'not_applicable', value: 'Not applicable' },
  { key: 'bc', value: 'British Council' },
  { key: 'pe_cluster', value: 'PE Cluster' },
  { key: 'au.platform.sh', value: 'Platform.sh AU' },
  { key: 'ca-1.platform.sh', value: 'Platform.sh CA 1' },
  { key: 'de-1.platform.sh', value: 'Platform.sh DE 1' },
  { key: 'de-2.platform.sh', value: 'Platform.sh DE 2' },
  { key: 'eu.platform.sh', value: 'Platform.sh EU' },
  { key: 'eu-2.platform.sh', value: 'Platform.sh EU 2' },
  { key: 'eu-3.platform.sh', value: 'Platform.sh EU 3' },
  { key: 'eu-4.platform.sh', value: 'Platform.sh EU 4' },
  { key: 'fr-1.platform.sh', value: 'Platform.sh FR 1' },
  { key: 'platform.sh_fr_2', value: 'Platform.sh FR 2' },
  { key: 'nl-1.platform.sh', value: 'Platform.sh NL 1' },
  { key: 'us.platform.sh', value: 'Platform.sh US' },
  { key: 'us-2.platform.sh', value: 'Platform.sh US 2' },
  { key: 'us-3.platform.sh', value: 'Platform.sh US 3' },
  { key: 'uk-1.platform.sh', value: 'Platform.sh UK 1' },
  { key: 'eu.s5y.io', value: 'SymfonyCloud EU' },
  { key: 'us.s5y.io', value: 'SymfonyCloud US' },
  { key: 'china_azure', value: 'China Azure' },
  { key: 'magento_cloud_pe', value: 'Magento Cloud PE' },
  { key: 'us.magento.cloud', value: 'Magento Cloud US' },
  { key: 'us-3.magento.cloud', value: 'Magento Cloud US 3' },
  { key: 'eu.magento.cloud', value: 'Magento Cloud EU' },
  { key: 'eu-3.magento.cloud', value: 'Magento Cloud EU 3' },
  { key: 'ap-3.magento.cloud', value: 'Magento Cloud AP 3' },
  { key: 'us-4.magento.cloud', value: 'Magento Cloud US 4' },
  { key: 'eu-4.magento.cloud', value: 'Magento Cloud EU 4' },
  { key: 'ap-4.magento.cloud', value: 'Magento Cloud AP 4' },
  { key: 'eu-5.magento.cloud', value: 'Magento Cloud EU 5' },
  { key: 'us-5.magento.cloud', value: 'Magento Cloud US 5' },
  { key: 'eu-6.magento.cloud', value: 'Magento Cloud EU 6' },
]

const supportPlans = [
  { key: 'standard', value: 'Standard' },
  { key: 'elite', value: 'Elite' },
  { key: 'enterprise', value: 'Enterprise' },
  { key: 'premier', value: 'Premier' },
  { key: 'trial', value: 'Trial' },
  { key: 'internal', value: 'Internal' },
  { key: 'sp_mc_cloud_pro', value: 'Magento Pro' },
  { key: 'sp_mc_cloud_starter', value: 'Magento Starter' },
  { key: 'sp_mc_cloud_trial-starter', value: 'Magento Trial-Starter' },
  { key: 'sp_mc_cloud_trial-pro', value: 'Magento Trial-Pro' },
]

const getValue = (arr, key) => {
  const match = arr.find(a => a.key === key)
  return match ? match.value : ''
}

export const zendeskTransform = ticket => ({
  id: ticket.ticket_id,
  summary: ticket.subject,
  href: `https://platformsh.zendesk.com/agent/tickets/${ticket.ticket_id}`,
  created: ticket.created,
  region: getValue(regions, ticket.region),
  supportPlan: getValue(supportPlans, ticket.support_plan),
  cloud: getValue(clouds, ticket.cloud),
  jira: ticket.jira.map(j => j.issue_key),
})

export const zendeskShape = {
  created: string.isRequired,
  href: string.isRequired,
  id: number.isRequired,
  region: string,
  summary: string.isRequired,
  supportPlan: string.isRequired,
}

// eslint-disable-next-line no-return-await
const zdFetch = async uri => await request(uri, 'GET')


export const fetchTickets = async uri => {
  const { count, tickets, _links } = await zdFetch(uri)
  return { count, tickets, next: _links.next && _links.next.href }
}
