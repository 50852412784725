import axios from 'axios'
import queryString from 'query-string'
import Client from 'platformsh-client'
import hash from 'hash-it'
import toQuerystring from 'to-querystring'

export const getApiConfig = () => ({ client_id: 'lighthouse@platform' })

const client = new Client(getApiConfig())

export const getAccessToken = async () => {
  const accessToken = JSON.parse(localStorage.getItem('atlassianAccessToken'))
  if (accessToken && accessToken.expires > Date.now()) {
    return accessToken.access_token
  }
  if (accessToken && accessToken.refresh_token) {
    try {
      const { data } = await axios.get('/api/reauthorize', { params: { refresh_token: accessToken.refresh_token } })
      localStorage.setItem('atlassianAccessToken', JSON.stringify(data) || 'error')
      return data.access_token
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
      return false
    }
  }
  if (process.env.NODE_ENV === 'production') {
    const query = queryString.parse(window.location.search)
    const authCode = query.code
    if (!authCode) {
      const { id } = await client.getAccountInfo()
      const encodedQuery = toQuerystring({
        audience: 'api.atlassian.com',
        client_id: 'ssnlrkD6k03XiaDawRDUyALRTr3jJkr5',
        prompt: 'consent',
        redirect_uri: 'https://lighthouse.pltfrm.sh/jira-connect',
        response_type: 'code',
        scope: 'read:jira-user read:jira-work write:jira-work offline_access',
        state: hash(id),
      })
      window.location.href = `https://auth.atlassian.com/authorize?${encodedQuery}`
      return false
    }
    try {
      const { data } = await axios.get('/api/authorize', { params: { code: authCode } })
      localStorage.setItem('atlassianAccessToken', JSON.stringify(data) || 'error')
      window.location.href = '/'
      return data.access_token
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
      return false
    }
  }
  return 'dummy'
}
